import React, { PureComponent } from 'react'
import { Grid } from '@material-ui/core'
import AttachFile from '@material-ui/icons/AttachFile'
import { FileUpload } from 'react-md'
import { without } from 'lodash/array'
import LinearProgress from '@material-ui/core/LinearProgress'
import UploadedFileCard from './UploadedFileCard'
import { attachmentApi } from '../../services/Api'
import './_style.scss'

const styles = {
  fileinput: {
    width: 256
  }
}

class SimpleFileUpload extends PureComponent {
  state = { files: [], progress: null, file: null }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  setFileUpload = (fileUpload) => {
    this.fileUpload = fileUpload
  }

  setFile = (file) => {
    this.setState({ file })
  }

  /**
   * This is triggered once a file has been successfully uploaded.
   *
   * @param {File} uploadedFile - the fully uploaded file. The properties
   *    of this object change depending on the browser, but normally
   *    the name, size, type, and lastModifiedDate are the same.
   * @param {String} uploadedData - This will be whatever the results of
   *    the upload was. So this could be the text in a file, a data-url
   *    for an image, or some other content for other file types.
   */
  handleLoad = async (uploadedFile, uploadedData) => {
    this.props.fetching(true)
    const { name, size, type, lastModified } = uploadedFile
    const messageId = this.props.messageId
    let content
    if (typeof uploadedData === 'string' || uploadedData instanceof String) {
      if (uploadedData.includes('base64')) {
        content = uploadedData.split(',').pop()
      } else {
        content = btoa(uploadedData)
      }
    } else {
      content = btoa(
        new Uint8Array(uploadedData)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      )
    }
    const body = {
      name,
      content
    }
    const response = await attachmentApi.createAttachment(messageId, body)
    if (response.ok) {
      const file = {
        id: response.data.id,
        name,
        size,
        type,
        data: uploadedData,
        lastModified: new Date(lastModified),
      }
      const files = [...this.state.files, file]
      // Show progress bar for one more second
      this.timeout = setTimeout(() => {
        this.timeout = null
        this.setState({ progress: null })
      }, 1000)
      this.props.handleFilesChange(files)
      this.setState({ files, progress: 100 })
      this.props.fetching(false)
    }
  }

  handleProgress = (file, progress) => {
    // The progress event can sometimes happen once more after the abort
    // has been called. So this just a sanity check
    if (this.state.file) {
      this.setState({ progress })
    }
  }

  removeFile = async (file) => {
    const messageId = this.props.messageId
    const response = await attachmentApi.deleteAttachment(messageId, file.id)
    if (response.ok) {
      const files = without(this.state.files, file)
      this.setState({ files })
      this.props.handleFilesChange(files)
    }
  }

  render() {
    const { initialFiles } = this.props
    if (initialFiles) {
      this.setState({ files: initialFiles })
    }
    const { progress, files } = this.state
    const uploadedFileCards = files.map((file => <UploadedFileCard key={file.id} file={file} onRemoveClick={this.removeFile} />))
    return (
      <div>
        {progress && <LinearProgress />}
        <FileUpload
          id="multiple-file-upload"
          name="multipart-file-upload"
          allowDuplicates
          multiple
          iconBefore
          icon={<AttachFile />}
          style={styles.fileinput}
          label="Selecionar Arquivos"
          ref={this.setFileUpload}
          onLoadStart={this.setFile}
          onProgress={this.handleProgress}
          onLoad={this.handleLoad}
          readAs='ArrayBuffer'
        />
        <Grid container direction='row' justify="flex-start">
          {uploadedFileCards}
        </Grid>
      </div>
    )
  }
}

export default (SimpleFileUpload)